import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { FormControl, InputLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { setGeneralStatus } from '../redux/actions-v2/coreAction';
import ModalLarge from './Base/ModalLarge';
import { useDispatch, useSelector } from 'react-redux';
import BuilderEmail from '../screens/settings/BuilderEmail';
import TextField from '../stories/general-components/TextField';
import Select from '../stories/general-components/Select';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';
import EmailVisual from './EmailVisual';
import EmailVisualCard from './EmailVisualCard';

const ModalSendEmail = ({ isOpen, type, modalCloseHandler }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isDarkmode = theme.palette.mode === 'dark';

  const { structureId, moduleName, elementId } = useParams();

  const [displaySide, setDisplaySide] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [visualBody, setVisualBody] = useState('');

  const handleChangeTemplate = (value) => {
    setSelectedTemplate(null);
    setIsLoaded(true);
    const templateData = businessPreference?.emails?.find(
      (template) => template.id === value
    );
    setSelectedTemplate(templateData);
  };

  const displaySettings = () => {
    setDisplaySide(!displaySide);
  };

  const businessPreference = useSelector((state) => state.core.businessData);

  const elementData = useSelector(
    (state) => state.element.singleElementDetails
  );

  function handleBodyChange(value) {
    setVisualBody(value);
  }

  useEffect(() => {
    if (elementData?.targetEmail) {
      setEmail(elementData?.targetEmail);
    }
    if (elementData?.email) {
      setEmail(elementData?.email);
    }
    if (elementData?.targetDetails?.email) {
      setEmail(elementData?.targetDetails?.email);
    }
    if (elementData?.name) {
      setName(elementData?.name);
    }
  }, [elementData]);

  const sendEmail = async () => {
    try {
      setIsLoading(true);
      const receivers = [
        {
          email: email,
          name: elementData?.targetDetails?.name || '',
        },
      ];
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'modal',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        method: 'POST',
        url: 'coreSeqV3/email',
        body: {
          receivers: receivers,
          templateId: selectedTemplate?.id || '',
          subject: subject,
          dependencyId: elementId,
          dependencyCollection:
            moduleName === 'cardsinvoiced' || moduleName === 'cardsuninvoiced'
              ? 'cards'
              : moduleName,
          bodyMessage: visualBody || '',
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'modal',
          type: 'pulse',
        })
      );
      modalCloseHandler();
    } catch (error) {
      console.error('Failed to send email');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  return (
    <div>
      <ModalLarge
        isOpen={isOpen}
        subTitle={displaySide ? t('editTemplateSub') : t('sendEmailSub')}
        labels={{
          primary: t('send'),
          secondary: t('cancel'),
        }}
        handlePrim={sendEmail}
        disabledPrim={displaySide}
        handleSec={modalCloseHandler}
        fullHeight={displaySide ? true : false}
        title={displaySide ? t('editTemplate') : t('sendEmail')}
        displaySettings={() => displaySettings()}
        fullWidth
        maxWidth="lg"
        icon={'EmailOutlined'}
        modalCloseHandler={modalCloseHandler}
      >
        {' '}
        {!isLoading && (
          <div className="row">
            <div
              style={{ paddingRight: '20px' }}
              className={displaySide ? 'col-12' : 'col-5'}
            >
              {!displaySide && (
                <div className={'col-12'}>
                  <Select
                    selections={businessPreference?.emails
                      ?.filter((e) => e.structureId === structureId)
                      ?.map((e) => ({
                        label: e.name,
                        id: e.id,
                        value: e.id,
                      }))}
                    noEmpty
                    label={t('template')}
                    value={selectedTemplate?.id || ''}
                    onChange={(e, value) => {
                      handleChangeTemplate(value);
                    }}
                  />
                  <TextField
                    label={t('email')}
                    primary={true}
                    name="email"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label={t('subject')}
                    primary={true}
                    name="subject"
                    fullWidth
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />

                  {selectedTemplate?.html?.includes('${data.customBody}') && (
                    <FormControl
                      fullWidth
                      margin="normal"
                      sx={{
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                        padding: '10px',
                        minHeight: '50px',
                      }}
                    >
                      <InputLabel
                        shrink={true}
                        sx={{
                          backgroundColor: isDarkmode
                            ? 'rgb(51,51,51)'
                            : '#FFF',
                          padding: '2px 10px 2px 10px',
                          borderRadius: '10px',
                        }}
                      >
                        {'Message'}
                      </InputLabel>{' '}
                      <ReactQuill
                        theme="bubble"
                        value={visualBody}
                        onChange={handleBodyChange}
                        modules={{
                          history: {
                            delay: 2000,
                            maxStack: 500,
                            userOnly: true,
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </div>
              )}
            </div>
            {displaySide ? (
              <div className="col-12">
                <BuilderEmail displaySettings={displaySettings} />
              </div>
            ) : (
              <div className="col-7">
                <EmailVisual
                  selectedTemplate={selectedTemplate}
                  elementData={elementData}
                  customMessage={visualBody}
                />
              </div>
            )}
          </div>
        )}
      </ModalLarge>
    </div>
  );
};

export default ModalSendEmail;
