import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as modalActions from '../../redux/actions/modal-actions.js';
import { EmojiButton } from '@joeattardi/emoji-button';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Popper,
  ListItemButton,
  AvatarGroup,
  Avatar,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
// utilities
import { useTranslation } from 'react-i18next';
import { db } from '../../firebase.js';
import {
  query,
  updateDoc,
  arrayUnion,
  collection,
  getDoc,
  where,
  limit,
  orderBy,
  onSnapshot,
  doc,
} from 'firebase/firestore';

// components
import { useTheme } from '@mui/material/styles';
import { Menu, MenuItem, FormControl, InputLabel } from '@mui/material';
import TextField from '../../stories/general-components/TextField.jsx';
import TextFieldMUI from '@mui/material/TextField';
import Blocks from '../../stories/layout-components/Block.jsx';
import MainLayoutV2 from '../../layouts/MainLayoutV2.jsx';
import {
  setGeneralStatus,
  setRoom,
} from '../../redux/actions-v2/coreAction.js';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';
import { Divider } from '@material-ui/core';
import ModalLarge from '../../modals/Base/ModalLarge.jsx';
import Button from '../../stories/general-components/Button.jsx';
import IconUploader from '../../components/@generalComponents/IconUploader.jsx';
import NodiesElements from './components/NodiesElements.jsx';
import NodiesPoints from './components/NodiesPoints.jsx';
import { ArrowUpwardOutlined } from '@mui/icons-material';
import HistoryMessage from '../../components/@generalComponents/HistoryMessage.jsx';
import MainSearch from '../../components/@generalComponents/layout/MainSearch/MainSearch.jsx';

const ListDrop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { nodeId } = useParams();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const currentPath = window.location.pathname;
  const currentPageTitle = document.title;

  const tabs = [
    { label: t('elements'), icon: 'PortraitOutlined', id: 0 },
    { label: t('points'), icon: 'ListAlt', id: 1 },
  ];

  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [allMessages, setAllMessages] = useState([]);
  const [openPopper, setOpenPopper] = useState(false);
  const [open, setOpen] = useState(false);
  const [valueServer, setValueServer] = useState('');
  const [nodeData, setNodeData] = useState(null);
  const [logMessage, setLogMessage] = useState('');
  const [clicked, setClicked] = useState(false);
  const [logMessageServer, setLogMessageServer] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [newDropData, setNewDropData] = useState(null);
  const [insideFolder, setInsideFolder] = useState(false);
  const [activeFolder, setActiveFolder] = useState(null);
  const buttonRef = useRef(null);
  const quillRef = useRef(null);

  const businessPreference = useSelector((state) => state.core.businessData);

  const currentStatus = useSelector((state) => state.core.status);
  const room = useSelector((state) => state.core.room);
  const presences = useSelector((state) => state.core.onCall);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const businessStructures = businessStructure?.structures || [];

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPop, setAnchorElPop] = useState(null);
  const [currentAddType, setCurrentAddType] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const activeIndex = parseInt(searchParams.get('tab')) || 0;
  const [availableTags, setAvailableTags] = useState([]);
  const [usersInRoom, setUsersInRoom] = useState({});

  useEffect(() => {
    if (nodeId && presences) {
      const users = Object.entries(presences).reduce(
        (acc, [userId, dataId]) => {
          if (dataId === nodeId) {
            acc[userId] = dataId;
          }
          return acc;
        },
        {}
      );

      const userIds = Object.keys(users);

      setUsersInRoom(userIds);
    }
  }, [presences, nodeId]);

  const handleTagsChange = (event, value) => {
    setNewDropData({ ...newDropData, tags: value });
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (list?.length > 0) {
      const tags = list
        .filter((element) => element?.data?.tags)
        .map((element) => element?.data?.tags)
        .flat()
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

      setAvailableTags([...new Set(tags)]);
    }
  }, [list]);

  const currentUser = useSelector((state) => state.core.user);

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    // Ensure only messagesContainerRef handles the scroll behavior
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [allMessages]);

  const fetchMetadata = async () => {
    if (!newDropData?.url) return;
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: 'nodies-link',
        type: 'pulse',
      })
    );
    setNewDropData({ url: newDropData?.url });
    try {
      const linkData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreMulti/metadata`,
        body: {
          url: newDropData?.url,
        },
      });
      setNewDropData({ ...newDropData, ...linkData });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'nodies-link',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  };

  const updatePosition = async () => {
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: 'position',
        type: 'pulse',
      })
    );

    try {
      const positionData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/position`,
        body: {
          pathId: nodeId,
          action: 'add',
        },
      });

      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'position',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error('Error position:', error);
    }
  };

  const businessDocId = businessPreference?.id;
  useEffect(() => {
    if (businessDocId && nodeId) {
      const lastMessageRef = doc(
        db,
        'businessesOnNode',
        businessDocId,
        'channels',
        nodeId
      );

      // Update the seenLast array to include the current user
      updateDoc(lastMessageRef, {
        seenLast: arrayUnion(currentUser?.uid),
      });
      updatePosition();
    }
  }, [currentUser?.uid, businessDocId, nodeId]);

  const handleLogCreation = async () => {
    if (logMessage.trim() === '' || !nodeId) return;

    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/log`,
        body: {
          elementId: nodeId,
          elementPath: 'businessesOnNode/' + businessPreference?.id,
          description: valueServer || logMessage,
          elementFinalType: 'workspace',
          documentPath: '/app/nodes/' + nodeId,
          name: currentUser?.displayName,
          userContactId: null,
        },
      });

      setLogMessage('');
      setLogMessageServer('');
    } catch (error) {
      console.error('Error creating log:', error);
    }
  };

  useEffect(() => {
    if (businessDocId) {
      const messagesRef = collection(
        db,
        'businessesOnNode',
        businessDocId,
        'logs'
      );
      const channelRef = doc(
        db,
        'businessesOnNode',
        businessDocId,
        'channels',
        nodeId
      );
      try {
        const q = query(
          messagesRef,
          orderBy('timeStamp', 'desc'),
          where('channelId', '==', channelRef),
          where('isDone', '==', false),
          limit(40)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newMessages = snapshot.docs
            .map((doc) => ({
              id: doc?.id,
              ...doc.data(),
            }))
            .sort((a, b) => {
              // First, sort by pin status
              if (a.isPinned && !b.isPinned) {
                return 1;
              } else if (!a.isPinned && b.isPinned) {
                return -1;
              }
              // Then, sort by creation time
              return a.timeStamp.toMillis() - b.timeStamp.toMillis();
            });

          setAllMessages(newMessages);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error(error, 'get message');
      }
    }
  }, [nodeId, businessDocId]);

  function handleBodyChange(value) {
    setNewDropData({ ...newDropData, description: value });
  }

  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['link'],
    // ['link', 'image', 'video', 'formula'],
    [{ size: ['small', 'medium'] }],
    [{ color: [] }],
    [{ align: [] }],
  ];

  const handleAddElement = async () => {
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: 'nodies-addNewElement',
        type: 'pulse',
      })
    );
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreSeqV3/node-element`,
        body: {
          name: newDropData?.title,
          structureId: '',
          type: currentAddType,
          dropId: nodeId,
          elementPath: newDropData?.url,
          data: newDropData,
          moduleId: '',
          collectionField: '',
        },
      });
      fetchRoomData();
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'nodies-addNewElement',
          type: 'pulse',
        })
      );
      handleModalClose();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomData = async () => {
    try {
      const docRef = doc(collection(db, 'rooms'), nodeId);
      const docSnapshot = await getDoc(docRef);
      const roomData = docSnapshot.data();
      setNodeData(roomData);
      setSelectedEmoji(null);
      dispatch(
        setRoom({
          sessionId: docSnapshot.data().sessionId,
          apiKey: docSnapshot.data().apiKey,
          id: nodeId,
          name: docSnapshot.data().roomName,
          groups: docSnapshot.data().groups,
          notes: docSnapshot.data().notes,
        })
      );
      setSelectedEmoji(roomData?.emoji);
    } catch (error) {
      console.error('Error fetching room data:', error);
    }
  };

  const handlePinMessage = async (messageId, currentPinStatus) => {
    const messageRef = doc(
      db,
      'businessesOnNode',
      businessDocId,
      'logs',
      messageId
    );

    const newPinStatus = !currentPinStatus;

    await updateDoc(messageRef, {
      isPinned: newPinStatus,
    });

    // Update the local state to reflect the change immediately
    setAllMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId ? { ...msg, isPinned: newPinStatus } : msg
      )
    );
  };

  useEffect(() => {
    const picker = new EmojiButton();
    picker.on('emoji', (selection) => {
      setSelectedEmoji(selection.emoji);
    });

    if (buttonRef.current) {
      buttonRef.current.addEventListener('click', () => {
        picker.pickerVisible
          ? picker.hidePicker()
          : picker.showPicker(buttonRef.current);
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (selectedEmoji !== nodeData?.emoji && selectedEmoji) {
        updateDrop(room, selectedEmoji);
      }
    }, 1000);
  }, [selectedEmoji]);

  useEffect(() => {
    if (nodeData) {
      const elements = nodeData?.elements || [];
      setList(elements);
    }
  }, [nodeData]);

  const handleElementDelete = async () => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'nodies-remove',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'DELETE',
        url: `coreSeqV3/node`,
        body: {
          dropId: nodeId,
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'nodies-remove',
          type: 'pulse',
        })
      );
      navigate('/app/dashboard');
    } catch (error) {
      console.error(error);
      dispatch(
        setGeneralStatus({
          status: 'error',
          position: 'nodies-remove',
          type: 'pulse',
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    dispatch(
      modalActions.modalConfirmation({
        isOpen: true,
        title: t('deleteElement'),
        handleConfirm: () => handleElementDelete(),
      })
    );
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Detect Cmd + Enter (Mac) or Ctrl + Enter (Windows/Linux)
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        if (quillRef.current?.editor?.hasFocus()) {
          handleLogCreation();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [logMessage, logMessageServer]);

  const updateDrop = async (roomDoc, emoji) => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'nodies-update',
          type: 'backdrop',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreSeqV3/node`,
        body: {
          name: roomDoc?.name,
          dropId: roomDoc?.id || nodeId,
          emoji: emoji || selectedEmoji,
          groups: roomDoc?.groups,
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'nodies-update',
          type: 'backdrop',
        })
      );
      fetchRoomData();
    } catch (error) {
      dispatch(
        setGeneralStatus({
          status: 'error',
          position: 'nodies-update',
          type: 'backdrop',
        })
      );
      console.error(error);
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = () => {
    handleLogCreation();
  };

  const handleChange = (value) => {
    let updatedValue = value;

    const lastWord = value.split(/\s+/).pop();
    const isMentionTriggered =
      lastWord.startsWith('@') || lastWord.startsWith('<p>@');

    if (isMentionTriggered) {
      setAnchorElPop(quillRef.current?.editor?.container);
      setOpenPopper(true);
    } else {
      setOpenPopper(false);
    }

    // Mention Replacement
    let updatedServerValue = updatedValue;
    const storedMentions = [];

    for (const mention of mentionSuggestions) {
      const {
        name,
        startIndex,
        path,
        structureId,
        documentId,
        collectionField,
      } = mention;

      // Add mention to the storage
      storedMentions.push({ name, startIndex, path });

      // Replace in the serverValue
      updatedServerValue = updatedServerValue.replace(
        `@${name}`,
        `<a id=${documentId} path=${path} structure=${structureId} from=${currentPath} collection=${collectionField} title=${currentPageTitle}> @${name} </a>`
      );
    }

    setValueServer(updatedServerValue);
    setLogMessage(value);
  };

  const addElement = (structureId, type) => {
    setNewDropData(null);
    if (type === 'structure') {
      dispatch(
        modalActions.modalElementCreation({
          isOpen: true,
          structureId: structureId,
          roomId: nodeId,
        })
      );
    } else {
      setCurrentAddType(type);
      setModalOpen(true);
    }
    handleMenuClose();
  };

  const modalAddElementState = useSelector(
    (state) => state.modalReducer.modalElementCreation.isOpen
  );

  useEffect(() => {
    if (modalAddElementState === false) {
      fetchRoomData();
      setActiveFolder(null);
      setInsideFolder(false);
    }
  }, [modalAddElementState, nodeId]);

  const handleModalClose = () => {
    setModalOpen(false);
    setNewDropData({
      title: newDropData?.title || '',
      tags: [],
    });
  };

  const handleUploadComplete = async (filesData) => {
    const fileUrls = filesData?.map((file) => file) || [];
    const newFiles = [...(newDropData?.file || []), ...fileUrls];
    setNewDropData({
      ...newDropData,
      fileUrl: newFiles[0]?.fileUrl,
      title: newFiles[0]?.originalFile?.originalFileName,
      mime: newFiles[0]?.originalFile?.mime,
      metadata: newFiles[0]?.originalFile?.metadata,
    });
  };

  const handleElementSelect = (elementPath, elementname) => {
    const updatedValue = logMessage?.replace(
      /@[^\s]*\s?$/,
      ` @${elementname} `
    );
    handleChange(updatedValue);

    setValueServer(
      valueServer +
        `<a id=${elementPath
          .split('/')
          .pop()} path=${elementPath} from=${currentPath} structure=${
          selectedCategory?.id
        } collection=${selectedCategory?.collection} > @${elementname} </a>`
    );

    //add to mentions suggestions
    const newMention = {
      name: elementname,
      path: elementPath,
      structureId: selectedCategory?.id,
      documentId: elementPath.split('/').pop(),
      collectionField: selectedCategory?.collection,
    };
    setMentionSuggestions((prevSuggestions) => [
      ...prevSuggestions,
      newMention,
    ]);

    handleClose();
    setTimeout(() => {
      const quill = quillRef.current?.getEditor();
      if (quill) {
        const contentLength = quill.getLength();
        quill.setSelection(contentLength - 1, 0);
      }
    }, 500);
  };

  const handleStructureSelect = (elementCollection, elementId) => {
    setSelectedCategory({ id: elementId, collection: elementCollection });
    setOpen(true);
    setOpenPopper(false);
  };

  return (
    <MainLayoutV2
      pageTitle={(selectedEmoji || '') + '  ' + room?.name || ''}
      subTitle={t('workspace')}
      roomData={true}
      deleteItem={handleConfirmDelete}
      tabs={tabs}
      usersInRoom={usersInRoom}
      roomId={nodeId}
      selectedTab={activeIndex || 0}
    >
      <ModalLarge
        isOpen={isModalOpen}
        modalCloseHandler={handleModalClose}
        title={t('add') + ' ' + t(currentAddType)}
      >
        <div className="row px-2">
          {currentAddType === 'link' && (
            <div>
              <TextField
                label={t('link')}
                fullWidth
                isLoading={
                  currentStatus?.status === 'loading' &&
                  currentStatus?.position === 'nodies-link'
                }
                value={newDropData?.url}
                type="link"
                onBlur={fetchMetadata}
                onChange={(e) =>
                  setNewDropData({ ...newDropData, url: e.target.value })
                }
              />
              {newDropData?.title && (
                <TextField
                  label={t('title')}
                  fullWidth
                  value={newDropData?.title}
                  type="title"
                  onBlur={fetchMetadata}
                  onChange={(e) =>
                    setNewDropData({ ...newDropData, title: e.target.value })
                  }
                />
              )}
            </div>
          )}
          {currentAddType === 'media' && (
            <div>
              <IconUploader
                key={'media'}
                value={newDropData?.file}
                fieldType={'media-single'}
                required={false}
                elementId={'files'}
                label={t('files')}
                elementType={t('files')}
                onComplete={handleUploadComplete}
              />
            </div>
          )}
          {currentAddType === 'note' && (
            <div>
              <FormControl
                fullWidth
                margin="normal"
                sx={{
                  border: '1px solid lightgray',
                  borderRadius: '10px',
                  padding: '10px',
                  minHeight: '50px',
                }}
              >
                <InputLabel
                  shrink={true}
                  sx={{
                    backgroundColor: '#FFF',
                    padding: '2px 10px 2px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {'Note'}
                </InputLabel>{' '}
                <ReactQuill
                  theme="bubble"
                  value={newDropData?.body}
                  onChange={handleBodyChange}
                  modules={{
                    history: {
                      delay: 2000,
                      maxStack: 500,
                      userOnly: true,
                    },
                  }}
                />
              </FormControl>
              <TextField
                label={t('title')}
                fullWidth
                value={newDropData?.title}
                type="title"
                onChange={(e) =>
                  setNewDropData({ ...newDropData, title: e.target.value })
                }
              />
            </div>
          )}
          <div className="mt-1">
            <Autocomplete
              multiple
              options={availableTags}
              value={newDropData?.tags || []}
              freeSolo
              onChange={handleTagsChange}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  variant="outlined"
                  label={t('folders')}
                  placeholder={t('addFolders')}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: businessPreference?.mainColor || '#000',
                        boxShadow: `0 0 0 0.2rem ${
                          businessPreference?.mainColor + '20'
                        }`,
                      },
                    },
                  }}
                />
              )}
            />
          </div>
          {newDropData?.title && (
            <div className="align-left px-2 mt-4">
              <Button
                fullWidth
                onClick={() => {
                  handleAddElement();
                }}
                label={t('add')}
              />
            </div>
          )}
        </div>
      </ModalLarge>

      <Dialog style={{ zIndex: 100000 }} open={open} onClose={handleClose}>
        <DialogContent>
          <div style={{ width: '450px', height: '450px' }}>
            <MainSearch
              fromExternal
              setClicked={setClicked}
              clicked={clicked}
              structureIden={selectedCategory?.id}
              onSelectReturn={handleElementSelect}
              structureCollection={selectedCategory?.collection}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Popper
        open={openPopper}
        anchorEl={anchorElPop}
        style={{
          zIndex: 10000,
          backgroundColor: isDarkMode ? 'rgb(51,51,51)' : '#fff',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          borderRadius: '10px',
        }}
      >
        <List>
          {businessStructures?.map((category) => (
            <ListItem divider dense key={category.id} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleStructureSelect(
                    category?.collectionField,
                    category?.id,
                    'element'
                  )
                }
              >
                <ListItemText primary={category?.name} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem divider dense key={'employees'} disablePadding>
            <ListItemButton
              onClick={() =>
                handleStructureSelect(
                  'employees',
                  businessPreference?.id,
                  'tag'
                )
              }
            >
              <ListItemText primary={t('teamMembers')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popper>
      <div className="d-flex mt-1">
        <div className="col-8">
          <Blocks
            height={1}
            noBorder
            heightPercentage={92}
            noScroll
            onClickAdd={handleOpenMenu}
            title={
              activeIndex === 0
                ? t('elements')
                : activeIndex === 1
                ? t('points')
                : t('notes')
            }
          >
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              elevation={0}
              sx={{
                marginLeft: '0.5rem',
              }}
            >
              <MenuItem key={'link'} onClick={() => addElement(null, 'link')}>
                {t('link')}
              </MenuItem>
              <MenuItem key={'media'} onClick={() => addElement(null, 'media')}>
                {t('media')}
              </MenuItem>
              <MenuItem key={'note'} onClick={() => addElement(null, 'note')}>
                {t('note')}
              </MenuItem>
              <Divider component="li" />
              {businessStructures
                ?.slice()
                .sort((a, b) => a[`name`]?.localeCompare(b[`name`]))
                ?.map((structure) => (
                  <MenuItem
                    key={structure?.id}
                    onClick={() => addElement(structure?.id, 'structure')}
                  >
                    {structure?.[`name`]}
                  </MenuItem>
                ))}
            </Menu>

            {activeIndex === 0 && (
              <NodiesElements
                list={list}
                availableTags={availableTags}
                fetchRoomData={fetchRoomData}
                mainColor={businessPreference?.mainColor || '#000000'}
                insideFolder={insideFolder}
                setInsideFolder={setInsideFolder}
                activeFolder={activeFolder}
                setActiveFolder={setActiveFolder}
              />
            )}
            {activeIndex === 1 && <NodiesPoints />}
          </Blocks>
        </div>

        <div className="col-4">
          <Blocks
            title={t('messages')}
            height={1}
            heightPercentage={92}
            noBorder
            className="mt-1"
          >
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  paddingLeft: '22px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: `calc(${80}vh - 140px)`,
                  paddingBottom: '0px',
                }}
                ref={messagesContainerRef}
              >
                {allMessages?.map((message, index) => (
                  <div key={index}>
                    <HistoryMessage
                      userId={message?.assignedToId}
                      messageId={message?.id}
                      type={message?.name}
                      text={message?.description}
                      badge={0}
                      name={message?.name}
                      reactions={message?.reactions}
                      timestamp={moment
                        .unix(
                          message?.timeStamp?.seconds ||
                            message?.timeStamp?._seconds ||
                            ''
                        )
                        .fromNow()}
                    />
                  </div>
                ))}
              </div>
              <div
                style={{
                  position: 'fixed',
                  width: '100%',
                  marginLeft: '-18px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: '5px',
                  overflow: 'hidden',
                  bottom: 0,
                  backgroundColor: isDarkMode ? '#1a1a1a' : '#FFFFFF',
                }}
              >
                <div style={{ position: 'relative' }} className="mt-2">
                  <ReactQuill
                    ref={quillRef}
                    value={logMessage}
                    key={nodeId + 'messages'}
                    onChange={handleChange}
                    theme="snow"
                    placeholder={t('enterTextType')}
                    style={{ minHeight: '140px' }}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                  />
                  <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                    <IconButton
                      color="white"
                      style={{
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        backgroundColor: businessPreference?.secColor,
                      }}
                      onClick={() => handleKeyDown()}
                    >
                      <ArrowUpwardOutlined />
                    </IconButton>
                  </div>
                </div>
              </div>{' '}
            </div>
          </Blocks>
        </div>
      </div>
    </MainLayoutV2>
  );
};

export default ListDrop;
