import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SiteLayout from '../SiteLayout';

const Docs = () => {
  const { t } = useTranslation();
  const contentRef = useRef(null);

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    if (contentRef.current) {
      const element = contentRef.current.querySelector(`#${targetId}`);
      if (element) {
        // Scroll the container (contentRef) smoothly to the target element.
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  return (
    <SiteLayout
      helmetData={{
        name: t('API Documentation'),
        description: t('Developer Documentation for API Routes'),
      }}
    >
      <div className="row">
        {/* Sidebar Menu */}
        <div className="col-3">
          <div>
            {/* Public API Group */}
            <div>
              <h3 className="menu-group-title">Public API</h3>
              <ul>
                <li>
                  <a
                    href="#introduction"
                    onClick={(e) => handleScroll(e, 'introduction')}
                  >
                    Introduction
                  </a>
                </li>
                <li>
                  <a
                    href="#get-couples"
                    onClick={(e) => handleScroll(e, 'get-couples')}
                  >
                    GET /api/couples
                  </a>
                </li>
                <li>
                  <a
                    href="#post-couples"
                    onClick={(e) => handleScroll(e, 'post-couples')}
                  >
                    POST /api/couples
                  </a>
                </li>
              </ul>
            </div>
            {/* Limitations Group */}
            <div className="menu-group">
              <h3 className="menu-group-title">Limitations</h3>
              <ul>
                <li>
                  <a
                    href="#structures"
                    onClick={(e) => handleScroll(e, 'structures')}
                  >
                    Structures
                  </a>
                </li>
              </ul>
            </div>
            {/* Make Action Modules Group */}
            <div className="menu-group">
              <h3 className="menu-group-title">Make Action Modules</h3>
              <ul>
                <li>
                  <a
                    href="#auth-make"
                    onClick={(e) => handleScroll(e, 'auth-make')}
                  >
                    Authentification
                  </a>
                </li>
                <li>
                  <a
                    href="#create-element"
                    onClick={(e) => handleScroll(e, 'create-element')}
                  >
                    Create an Element
                  </a>
                </li>
                <li>
                  <a
                    href="#get-single-element"
                    onClick={(e) => handleScroll(e, 'get-single-element')}
                  >
                    Get Single Element
                  </a>
                </li>
                <li>
                  <a
                    href="#search-element"
                    onClick={(e) => handleScroll(e, 'search-element')}
                  >
                    Search an Element
                  </a>
                </li>
                <li>
                  <a
                    href="#update-element"
                    onClick={(e) => handleScroll(e, 'update-element')}
                  >
                    Update an Element
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Main Documentation Content */}
        <div
          style={{ maxHeight: '80vh', overflow: 'auto' }}
          className="col-9"
          ref={contentRef}
        >
          {/* ---------------------- Introduction ---------------------- */}
          <section className="docs-section" id="introduction">
            <h1 className="docs-title">API Documentation</h1>
            <p className="docs-intro">
              This documentation provides detailed information on how to
              interact with the API endpoints. Each section outlines the route,
              expected request/response formats, and potential error codes.
            </p>
          </section>

          {/* ---------------------- GET /api/couples ---------------------- */}
          <section className="docs-section" id="get-couples">
            <h2 className="docs-section-title">GET /api/couples</h2>
            <p className="docs-section-description">
              Retrieves a list of couples along with their details.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Request</h3>
              <pre className="docs-code">
                <code>
                  {`GET /api/couples HTTP/1.1
Host: api.example.com
Authorization: Bearer {token}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Response</h3>
              <p>
                On success, the endpoint returns a JSON object containing the
                list of couples.
              </p>
              <pre className="docs-code">
                <code>
                  {`{
  "status": "success",
  "data": [
    {
      "id": 1,
      "partner1": { "firstName": "John", "lastName": "Doe" },
      "partner2": { "firstName": "Jane", "lastName": "Doe" },
      "relationshipStatus": "married"
    }
  ]
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Errors</h3>
              <ul className="docs-list">
                <li>
                  <strong>401 Unauthorized:</strong> Invalid or missing
                  authentication token.
                </li>
                <li>
                  <strong>500 Internal Server Error:</strong> An unexpected
                  server error occurred.
                </li>
              </ul>
            </div>
          </section>

          {/* ---------------------- POST /api/couples ---------------------- */}
          <section className="docs-section" id="post-couples">
            <h2 className="docs-section-title">POST /api/couples</h2>
            <p className="docs-section-description">
              Creates a new couple entry in the system.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Request</h3>
              <p>
                The request body must be a JSON object with the following
                structure:
              </p>
              <pre className="docs-code">
                <code>
                  {`POST /api/couples HTTP/1.1
Host: api.example.com
Authorization: Bearer {token}
Content-Type: application/json

{
  "partner1": {
    "firstName": "John",
    "lastName": "Doe",
    "age": 30
  },
  "partner2": {
    "firstName": "Jane",
    "lastName": "Doe",
    "age": 28
  },
  "relationshipStatus": "married"
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Response</h3>
              <p>
                On success, the endpoint returns the newly created couple object
                including its unique identifier.
              </p>
              <pre className="docs-code">
                <code>
                  {`{
  "status": "success",
  "data": {
    "id": 10,
    "partner1": { "firstName": "John", "lastName": "Doe", "age": 30 },
    "partner2": { "firstName": "Jane", "lastName": "Doe", "age": 28 },
    "relationshipStatus": "married"
  }
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Errors</h3>
              <ul className="docs-list">
                <li>
                  <strong>400 Bad Request:</strong> Required fields are missing
                  or invalid.
                </li>
                <li>
                  <strong>401 Unauthorized:</strong> Invalid or missing
                  authentication token.
                </li>
                <li>
                  <strong>500 Internal Server Error:</strong> An unexpected
                  server error occurred.
                </li>
              </ul>
            </div>
          </section>

          {/* ---------------------- PUT /api/couples/:id ---------------------- */}
          <section className="docs-section" id="put-couple">
            <h2 className="docs-section-title">PUT /api/couples/:id</h2>
            <p className="docs-section-description">
              Updates an existing couple entry identified by its ID.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Request</h3>
              <p>
                The request body should include only the fields that need
                updating.
              </p>
              <pre className="docs-code">
                <code>
                  {`PUT /api/couples/10 HTTP/1.1
Host: api.example.com
Authorization: Bearer {token}
Content-Type: application/json

{
  "relationshipStatus": "divorced"
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Response</h3>
              <p>On success, the endpoint returns the updated couple object.</p>
              <pre className="docs-code">
                <code>
                  {`{
  "status": "success",
  "data": {
    "id": 10,
    "partner1": { "firstName": "John", "lastName": "Doe", "age": 30 },
    "partner2": { "firstName": "Jane", "lastName": "Doe", "age": 28 },
    "relationshipStatus": "divorced"
  }
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Errors</h3>
              <ul className="docs-list">
                <li>
                  <strong>400 Bad Request:</strong> Invalid data provided.
                </li>
                <li>
                  <strong>401 Unauthorized:</strong> Invalid or missing
                  authentication token.
                </li>
                <li>
                  <strong>404 Not Found:</strong> Couple with the given ID does
                  not exist.
                </li>
                <li>
                  <strong>500 Internal Server Error:</strong> An unexpected
                  server error occurred.
                </li>
              </ul>
            </div>
          </section>

          {/* ---------------------- DELETE /api/couples/:id ---------------------- */}
          <section className="docs-section" id="delete-couple">
            <h2 className="docs-section-title">DELETE /api/couples/:id</h2>
            <p className="docs-section-description">
              Deletes an existing couple entry identified by its ID.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Request</h3>
              <pre className="docs-code">
                <code>
                  {`DELETE /api/couples/10 HTTP/1.1
Host: api.example.com
Authorization: Bearer {token}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Response</h3>
              <p>On success, the endpoint returns a confirmation message.</p>
              <pre className="docs-code">
                <code>
                  {`{
  "status": "success",
  "message": "Couple deleted successfully"
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Errors</h3>
              <ul className="docs-list">
                <li>
                  <strong>401 Unauthorized:</strong> Invalid or missing
                  authentication token.
                </li>
                <li>
                  <strong>404 Not Found:</strong> Couple with the given ID does
                  not exist.
                </li>
                <li>
                  <strong>500 Internal Server Error:</strong> An unexpected
                  server error occurred.
                </li>
              </ul>
            </div>
          </section>

          {/* ---------------------- Error Codes ---------------------- */}
          <section className="docs-section" id="error-codes">
            <h2 className="docs-section-title">Error Codes</h2>
            <p className="docs-section-description">
              This section describes the common error codes returned by the API.
            </p>
            <div className="docs-block">
              <ul className="docs-list">
                <li>
                  <strong>400:</strong> Bad Request – The request was invalid.
                </li>
                <li>
                  <strong>401:</strong> Unauthorized – Authentication failed or
                  was not provided.
                </li>
                <li>
                  <strong>404:</strong> Not Found – The requested resource does
                  not exist.
                </li>
                <li>
                  <strong>500:</strong> Internal Server Error – An unexpected
                  server error occurred.
                </li>
              </ul>
            </div>
          </section>

          {/* ---------------------- Structures (Limitations) ---------------------- */}
          <section className="docs-section" id="structures">
            <h2 className="docs-section-title">Structures</h2>
            <p className="docs-section-description">
              This section outlines the limitations regarding the data
              structures.
            </p>
            {/* (Add any additional content here regarding structures limitations) */}
          </section>

          {/* ---------------------- Create an Element ---------------------- */}
          <section className="docs-section" id="create-element">
            <h2 className="docs-section-title">Create an Element</h2>
            <p className="docs-section-description">
              This action module creates a new element.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Communication</h3>
              <pre className="docs-code">
                <code>
                  {`{
	"url": "/create",
	"method": "POST",
	"headers": {
		 "connection": "{{connection}}"
	}, 
	"qs": {},
	"body": "{{omit(parameters, 'id')}}",
	"response": {
		"output": "{{body}}"
	}
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Mappable Params</h3>
              <pre className="docs-code">
                <code>
                  {`[
	{
		"name": "documentPath",
		"type": "path",
		"label": "Element Path",
		"required": true
	},
	{
		"name": "selectedStructureId",
		"type": "select",
		"label": "Select Structure",
		"required": true,
		"options": {
			"store": "rpc://structuresAccount",
			"nested": [
				"rpc://fields"
			]
		}
	}
]`}
                </code>
              </pre>
            </div>
          </section>

          {/* ---------------------- Get Single Element ---------------------- */}
          <section className="docs-section" id="get-single-element">
            <h2 className="docs-section-title">Get Single Element</h2>
            <p className="docs-section-description">
              This action module retrieves a single element.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Communication</h3>
              <pre className="docs-code">
                <code>
                  {`{
	"url": "/single",
	"method": "POST",
	"headers": {
		 "connection": "{{connection}}"
	}, 
	"qs": {},
	"body": "{{omit(parameters, 'id')}}",
	"response": {
		"output": "{{body}}"
	}
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Mappable Params</h3>
              <pre className="docs-code">
                <code>
                  {`[
	{
		"name": "documentPath",
		"type": "path",
		"label": "Element Path",
		"required": true
	}
]`}
                </code>
              </pre>
            </div>
          </section>

          {/* ---------------------- Search an Element ---------------------- */}
          <section className="docs-section" id="search-element">
            <h2 className="docs-section-title">Search an Element</h2>
            <p className="docs-section-description">
              This action module performs a search for elements.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Communication</h3>
              <pre className="docs-code">
                <code>
                  {`{
	"url": "/search",
	"method": "POST",
	"headers": {
		 "connection": "{{connection}}"
	}, 
	"qs": {},
	"body": "{{omit(parameters, 'id')}}",
	"response": {
		"output": "{{body}}"
	}
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Mappable Params</h3>
              <pre className="docs-code">
                <code>
                  {`[
    {
        "name": "selectedStructureId",
        "type": "select",
        "label": "Select Structure",
        "required": true,
        "options": "rpc://structuresAccount"
    },
    {
        "name": "search",
        "type": "text",
        "label": "Search",
        "help": "The string to use in the fulltext search. For example, \`John Doe\`."
    },
    {
        "name": "limit",
        "type": "uinteger",
        "label": "Limit",
        "default": 10,
        "help": "Maximum number of returned results."
    }
]`}
                </code>
              </pre>
            </div>
          </section>

          {/* ---------------------- Update an Element ---------------------- */}
          <section className="docs-section" id="update-element">
            <h2 className="docs-section-title">Update an Element</h2>
            <p className="docs-section-description">
              This action module updates an existing element.
            </p>
            <div className="docs-block">
              <h3 className="docs-subtitle">Communication</h3>
              <pre className="docs-code">
                <code>
                  {`{
	"url": "/update",
	"method": "POST",
	"headers": {
		 "connection": "{{connection}}"
	}, 
	"qs": {},
	"body": "{{omit(parameters, 'id')}}",
	"response": {
		"output": "{{body}}"
	}
}`}
                </code>
              </pre>
            </div>
            <div className="docs-block">
              <h3 className="docs-subtitle">Mappable Params</h3>
              <pre className="docs-code">
                <code>
                  {`[
	{
		"name": "documentPath",
		"type": "path",
		"label": "Element Path",
		"required": true
	},
	{
		"name": "selectedStructureId",
		"type": "select",
		"label": "Select Structure",
		"required": true,
		"options": {
			"store": "rpc://structuresAccount",
			"nested": [
				"rpc://fields"
			]
		}
	}
]`}
                </code>
              </pre>
            </div>
          </section>
        </div>
      </div>
    </SiteLayout>
  );
};

export default Docs;
